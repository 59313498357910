<template>
  <div class="special-ico">
    <Header titleurl="special"/>
    <div class="content">
      <section>
        <div class="container" align="center">
          <img class="container_special" src="../../assets/img/special-title-ico.png" width="200" height="40">
        </div>
        <div class="container">
          <div class="container_row">
            <div class="container_row_spec" v-for="(item,i) in productlist" :key="i">
              <router-link :to="'/product/detail?uniqueNo='+item.uniqueNo" target="_blank">
                <img class="container_row_img" :src="item.headImg" width="220" height="220">
                <div class="container_row_name"><img
                    :src="require('../../assets/img/special-ico.png')"><span>{{item.title}}</span></div>
                <div class="container_row_price" v-if="item.isServiceGoods!=1"><span
                    class="rmb">￥</span><span>{{item.price}}</span></div>
                <div class="container_row_price" v-if="item.isServiceGoods==1"> <span>{{item.billingVal}}<span
                      class="mode">（{{item.billingMode}}）</span></span></div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="clearfix"></div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import { productgetspecialproductlist } from '@/api/product';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      firstShow: true,
      show: false,
      height: '600',
      productlist: [],
    };
  },
  methods: {
    isShow() {
      this.show = !this.show;
    },
    hideDetail() {
      this.detailShow = false;
    },
    onproductgetspecialproductlist() {
      var that = this;
      productgetspecialproductlist({ count: 8 }).then((res) => {
        console.log(res)
        if (res.data.code === 1) {
          that.productlist = res.data.result;
        }
      });
    },
  },
  mounted() {
    screenLeft;
  },
  created() {
    this.onproductgetspecialproductlist();
  },
};
</script>
<style  scoped >
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.special-ico {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.content {
  width: 100%;
  margin-top: 20px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 700px;
}

.special-ico .clearfix {
  clear: both;
}

.container_special {
  margin: 20px auto;
}
.container_row {
  margin: 0px auto;
}
.container_row_spec {
  width: 220px;
  height: 303px;
  background-color: #fff;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s linear;
}
.container_row_spec:hover {
  transform: translate3d(0, -2px, 0);
}
.container_row_img {
  cursor: pointer;
}
.container_row_spec:nth-child(5),
.container_row_spec:nth-child(10) {
  margin-right: 0px !important;
}
.container_row_name {
  color: #666;
  height: 35px;
  font-size: 12px;
  width: 100%; /* margin-top: -4px; */
  background-color: #fff;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 40px;
}
.container_row_name img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}
.container_row_price {
background-color: #fff;
    font-size: 20px;
    color: #b31e22;
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
}
.container_row_price .rmb {
  font-size: 12px;
  color: #666;
}
.container_row_spec {
  height: auto;
}
.container_row_price .mode {
  font-size: 12px;
  color: #666;
}
</style>
